import '../styles/ErrorPage.scss'
import App from '../components/App'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import { Link } from 'gatsby'
import { selectDeviceSize } from '../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const ServerError = () => {
  const wWidth = useSelector(selectDeviceSize)

  return (
    <App>
      <SEO
        nofollow
        noindex
        title="Нам не удалось загрузить страницу | Youtalk"
      />
      <Header />

      <section className="not-found">
        <div className="container">
          <div className="row">
            <div className="designer-col col-12">
              <div className="not-found__middle">
                <h1 className="not-found__title">
                  Нам не удалось
                  <br />
                  загрузить страницу
                </h1>
                <p className="not-found__desc">
                  Команда инженеров YouTalk уже разбирается с проблемой, поэтому
                  нужно подождать пару минут и перезагрузить страницу. По любым
                  вопросам пишите на{' '}
                  <a href="mailto:care@youtalk.ru">care@youtalk.ru</a> — вместе
                  мы&nbsp;со&nbsp;всем&nbsp;справимся.
                </p>
                <Link className="button button_primary button_large" to="/">
                  Попробовать ещё раз
                </Link>
              </div>
            </div>
          </div>
        </div>
        {wWidth > 767 && (
          <>
            <img
              alt=""
              className="not-found__blob not-found__blob_top"
              src="/img/icons/404/top.svg"
            />
            <img
              alt=""
              className="not-found__blob not-found__blob_bot"
              src="/img/icons/404/bot.svg"
            />
          </>
        )}
      </section>
    </App>
  )
}

export default ServerError
